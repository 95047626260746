// dependencies.
import React from 'react'
import classNames from 'classnames'

// styles & images.
import 'src/components/pages/xo-swap/scss/ButtonWithStates.scss'

// main component.
const ButtonWithStates = ({ copy, disabled, state = 'initial', onClick }) => {
  return (
    <button
      className={classNames('x__xo-swap-button x__xo-swap-button--primary', {
        'x__xo-swap-button--disabled': disabled,
        'x__xo-swap-button--loading': state === 'loading',
        'x__xo-swap-button--success': state === 'success',
      })}
      disabled={disabled}
      onClick={onClick}
      tabIndex={0}
    >
      {state === 'initial' && <span className="x__xo-swap-button__text">{copy}</span>}
      {state === 'loading' && <span className="x__xo-swap-button__text">loading...</span>}
      {state === 'success' && <span className="x__xo-swap-button__success">⎷</span>}
    </button>
  )
}

export default ButtonWithStates
