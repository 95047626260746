// dependencies.
import React from 'react'
// components.
import Button from 'src/components/button'
import XoSwapButton from 'src/components/pages/xo-swap/Button'

// styles & images.
import 'src/components/pages/xo-swap/scss/HeaderSection.scss'

// partials.
const Banner = ({ data }) => (
  <div className="x__xo-swap-page__header__banner">
    {data.h2 && (
      <h2
        className="x__xo-swap-page__header__banner__heading"
        dangerouslySetInnerHTML={{ __html: data.h2 }}
      />
    )}

    {data.copy.length > 0 &&
      data.copy.map((copy, i) => (
        <p key={i} className="x__xo-swap-page__header__banner__description">
          {copy}
        </p>
      ))}

    {data.cta && <XoSwapButton copy={data.cta.copy} to={data.cta.path} target="_blank" />}
  </div>
)

// main component.
const HeaderSection = ({ data, enableSubscribe, onButtonClick }) => (
  <header className="x__xo-swap-page__header">
    <div className="x__xo-swap-page__header__content">
      {data.h3 && (
        <span
          className="x__xo-swap-page__header__content__supheading"
          dangerouslySetInnerHTML={{ __html: data.h3 }}
        />
      )}
      {data.h1 && (
        <h1
          className="x__xo-swap-page__header__content__heading"
          dangerouslySetInnerHTML={{ __html: data.h1 }}
        />
      )}
      {data.h2 && (
        <h2
          className="x__xo-swap-page__header__content__subheading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />
      )}

      <div className="x__xo-swap-page__header__content__actions">
        {enableSubscribe && (
          <Button copy={data.demoCta.copy} onClick={onButtonClick} size="largest" />
        )}

        {data.docsCta && (
          <Button copy={data.docsCta.copy} to={data.docsCta.path} size="largest" target="_blank" />
        )}
      </div>
    </div>

    {data.banner && <Banner data={data.banner} />}
  </header>
)

export default HeaderSection
