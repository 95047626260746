// dependencies.
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// styles & images.
import 'src/components/pages/xo-swap/scss/ExodusSection.scss'

// main component.
const InfoSection = ({ data, image }) => (
  <section className="x__xo-swap-page__exodus">
    <div className="x__xo-swap-page__exodus__content">
      <div className="x__xo-swap-page__exodus__content--left">
        {image && (
          <GatsbyImage
            image={image}
            alt={data.h2}
            loading="lazy"
            className="xo-swap-rellax"
            data-rellax-speed={-0.8}
          />
        )}
      </div>

      <div className="x__xo-swap-page__exodus__content--right">
        <h2
          className="x__xo-swap-page__exodus__content__heading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />
        {data.copy &&
          data.copy.map((paragraph, i) => (
            <p
              key={i}
              className="x__xo-swap-page__exodus__content__description"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
      </div>
    </div>
  </section>
)

export default InfoSection
