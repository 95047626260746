// dependencies.
import React from 'react'
// components.
import Button from 'src/components/button'

// styles & images.
import 'src/components/pages/xo-swap/scss/FooterSection.scss'

// main component.
const FooterSection = ({ data, enableSubscribe, onButtonClick }) => (
  <footer className="x__xo-swap-page__footer">
    <div className="x__xo-swap-page__footer__content">
      <h2
        className="x__xo-swap-page__footer__content__heading"
        dangerouslySetInnerHTML={{ __html: data.h2 }}
      />

      <p
        className="x__xo-swap-page__footer__content__description"
        dangerouslySetInnerHTML={{ __html: data.copy }}
      />

      {enableSubscribe && <Button copy={data.cta.copy} onClick={onButtonClick} size="largest" />}
    </div>
  </footer>
)

export default FooterSection
