// dependencies.
import React from 'react'
// components.
import Button from 'src/components/button'

// styles & images.
import 'src/components/pages/xo-swap/scss/FeaturesSection.scss'

// partials.
const BoxItem = ({ icon, title, copy }) => (
  <div className="x__box-item">
    {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
    {copy && <p dangerouslySetInnerHTML={{ __html: copy }} />}
    {icon && <i style={{ backgroundImage: `url('/xo-swap/img/icons/${icon}.png')` }} />}
  </div>
)

// main component.
const FeaturesSection = ({ data, enableSubscribe, onButtonClick }) => (
  <section className="x__xo-swap-page__features">
    <div className="x__xo-swap-page__features__content">
      {data.h2 && (
        <h2
          className="x__xo-swap-page__features__content__heading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />
      )}
      {data.copy && (
        <p
          className="x__xo-swap-page__features__content__description"
          dangerouslySetInnerHTML={{ __html: data.copy }}
        />
      )}

      {data.items?.length > 0 && (
        <div className="x__xo-swap-page__features__content__items">
          {data.items.map(({ key, h3, copy }) => (
            <BoxItem key={key} title={h3} copy={copy} icon={key} />
          ))}
        </div>
      )}

      {data.cta && (
        <div className="x__xo-swap-page__features__content__actions">
          {enableSubscribe && (
            <Button copy={data.cta.copy} onClick={onButtonClick} size="largest" />
          )}
        </div>
      )}
    </div>
  </section>
)

export default FeaturesSection
