// dependencies.
import classNames from 'classnames'
import React, { useState } from 'react'

// styles & images.
import 'src/components/pages/xo-swap/scss/FaqSection.scss'

// partials.
const DropdownIcon = ({ isOpen }) => (
  <i className={classNames('x__dropdown-toggle-icon', { 'x__dropdown-toggle-icon--open': isOpen })}>
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none">
      <rect
        x={0.707031}
        y={0.343262}
        width={15}
        height={1}
        rx={0.5}
        transform="rotate(45 0.707031 0.343262)"
        fill="white"
      />
      <rect
        width={15}
        height={1}
        rx={0.5}
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 10.6055 0.343262)"
        fill="white"
      />
    </svg>
  </i>
)

const ListItem = ({ q, a }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <li className={classNames('x__list-item', { 'x__list-item--open': isOpen })}>
      <p className="x__list-item__question" tabIndex={0} onClick={() => setIsOpen(!isOpen)}>
        {q}

        <DropdownIcon isOpen={isOpen} />
      </p>

      <span className="x__list-item__answer" dangerouslySetInnerHTML={{ __html: a }} />
    </li>
  )
}

// main component.
const FaqSection = ({ data, image }) => {
  return (
    <section className="x__xo-swap-page__faq">
      <div className="x__xo-swap-page__faq__content">
        {data.h2 && (
          <h2
            className="x__xo-swap-page__faq__content__heading"
            dangerouslySetInnerHTML={{ __html: data.h2 }}
          />
        )}

        {data.items.length > 0 && (
          <ul className="x__xo-swap-page__faq__content__items">
            {data.items.map((item, i) => {
              if (!item.a) return null

              return <ListItem key={i} {...item} />
            })}
          </ul>
        )}
      </div>
    </section>
  )
}

export default FaqSection
