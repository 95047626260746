// dependencies.
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import XoSwapButton from 'src/components/pages/xo-swap/Button'
// utils.
import useDimensions from 'js/utils/hooks/useDimensions'

// styles & images.
import 'src/components/pages/xo-swap/scss/GuideSection.scss'

// main component.
const GuideSection = ({ data, image }) => {
  const { isMobile } = useDimensions()

  return (
    <section className="x__xo-swap-page__guide">
      <div className="x__xo-swap-page__guide__content">
        <div className="x__xo-swap-page__guide__content--left">
          {data.h2 && (
            <h2
              className="x__xo-swap-page__guide__content__heading"
              dangerouslySetInnerHTML={{ __html: data.h2 }}
            />
          )}
          {data.copy && (
            <p
              className="x__xo-swap-page__guide__content__description"
              dangerouslySetInnerHTML={{ __html: data.copy }}
            />
          )}

          {!isMobile && data.cta && (
            <XoSwapButton
              copy={data.cta.copy}
              to={data.cta.path}
              forceExternal
              size="largest"
              target="_blank"
            />
          )}
        </div>

        <div className="x__xo-swap-page__guide__content--right">
          {image && (
            <GatsbyImage
              image={image}
              alt={data.h2}
              loading="lazy"
              className="xo-swap-rellax"
              data-rellax-speed={0.8}
            />
          )}

          {isMobile && data.cta && (
            <XoSwapButton
              copy={data.cta.copy}
              to={data.cta.path}
              forceExternal
              size="largest"
              target="_blank"
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default GuideSection
