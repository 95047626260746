// dependencies.
import React from 'react'
import { Link } from 'gatsby'

// Styles & Images:
import 'src/components/pages/xo-swap/scss/Button.scss'

// partials.
const Wrapper = ({ type, external, target, style, to, onClick, children }) => {
  const enabledTo = external ? { href: to, target } : { to, target }

  const props = {
    className: `x__button-xo-swap x__button-xo-swap--${type}`,
    tabIndex: 0,
    style,
    onClick,
    children,
  }

  if (external) return <a {...enabledTo} {...props} />
  if (!to) return <button {...props} />
  return <Link {...enabledTo} {...props} />
}

// main component,
const Button = ({
  type = 'primary',
  copy = 'Exodus button',
  target = '_self',
  style,
  to,
  forceExternal = false,
  onClick,
}) => {
  const external = forceExternal || (to && to.slice(0, 8) === 'https://')
  const props = { type, to, external, target, style, onClick }

  return (
    <Wrapper {...props}>
      <span className="x__copy">{copy}</span>
    </Wrapper>
  )
}

export default Button
