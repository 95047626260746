// dependencies.
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
// components.
import XoSwapButton from 'src/components/pages/xo-swap/Button'
// utils.
import useDimensions from 'js/utils/hooks/useDimensions'

// styles & images.
import 'src/components/pages/xo-swap/scss/BannerSection.scss'

// partials.
const Testimony = ({ copy, author, logo, largeCopy }) => {
  const backgroundImage = `url('/xo-swap/img/testimonials/${logo}.svg'), url('/xo-swap/img/testimonials/${logo}.png')`

  return (
    <p className={classNames('x__testimony', { 'x__testimony--large': largeCopy })}>
      {copy && <q dangerouslySetInnerHTML={{ __html: copy }} />}
      {author && <span dangerouslySetInnerHTML={{ __html: author }} />}
      {logo && <i style={{ backgroundImage }} />}
    </p>
  )
}

const ChromeImage = ({ name, index, speed }) => {
  const backgroundImage = `url('/xo-swap/img/${name}-banner-chrome-${index}.png')`

  return (
    <span
      className={classNames(`x__chrome x__chrome--${index}`, {
        'xo-swap-rellax': speed,
      })}
      style={{ backgroundImage }}
      data-rellax-speed={speed}
      role="presentation"
    />
  )
}

// main component.
const BannerSection = ({ data, image, smallHeading = false, withChromes = false }) => {
  const { isMobile } = useDimensions()

  return (
    <section className="x__xo-swap-page__banner">
      <div className="x__xo-swap-page__banner__background-container">
        <div
          className={classNames('x__xo-swap-page__banner__content', {
            'x__xo-swap-page__banner__content--no-image': !image,
          })}
        >
          <div className="x__xo-swap-page__banner__content--left">
            {data.h2 && (
              <h2
                className={classNames('x__xo-swap-page__banner__content__heading', {
                  'x__xo-swap-page__banner__content__heading--small': smallHeading,
                })}
                dangerouslySetInnerHTML={{ __html: data.h2 }}
              />
            )}
            {data.copy && (
              <p
                className="x__xo-swap-page__banner__content__description"
                dangerouslySetInnerHTML={{ __html: data.copy }}
              />
            )}

            {data.testimonials?.length > 0 &&
              data.testimonials.map((testimony, i) => (
                <Testimony key={i} {...testimony} logo={data.key} largeCopy={smallHeading} />
              ))}
          </div>

          <div className="x__xo-swap-page__banner__content--right">
            {image && <GatsbyImage image={image} alt={data.h2} loading="lazy" />}
            {data.cta && (
              <XoSwapButton
                copy={data.cta.copy}
                to={data.cta.path}
                target="_blank"
                type="secondary"
              />
            )}
          </div>

          {!isMobile && withChromes && (
            <>
              <ChromeImage name={data.key} index={0} speed={0.8} />
              <ChromeImage name={data.key} index={1} speed={0.8} />
              <ChromeImage name={data.key} index={2} speed={1} />
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default BannerSection
